<template>
  <b-row>
    <b-col sm="6">
      <b-card title="เงินคงเหลือ 🙌" sm="6">
        <b-card-text>
          <balance-box />
        </b-card-text>
      </b-card>
      <b-card title="รายละเอียดการถอน 🙌" sm="6">
        <validation-observer ref="withdrawForm" #default="{invalid}">
          <b-form-group>
            <span>ยอดเดิมพันรวมสะสม(เทิร์น): </span>
            <span> {{ turnover }}</span>
          </b-form-group>
          <b-form-group>
            <label>ระบุจำนวนเงินที่ต้องการถอน</label>
            <validation-provider
              #default="{ errors }"
              rules="required|integer"
              name="Amount"
            >
              <b-form-input
                v-model="amount"
                :state="errors.length > 0 ? false : null"
                placeholder="ระบุเฉพาะตัวเลขเท่านั้น"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-button
            variant="warning"
            type="submit"
            block
            :disabled="invalid"
            @click="withdraw"
          >
            ถอนเงิน
          </b-button>
        </validation-observer>
        <b-alert variant="warning">
          <div class="alert-body">
            <span class="ml-25">** ถอนขั้นต่ำ 300 บาท</span>
          </div>
        </b-alert>
      </b-card>
    </b-col>
    <b-col sm="6">
      <b-card title="รายการเทิร์นที่ต้องทำ 🙌" sm="6">
        <b-table responsive="sm" :items="turnLists" />
        <b-alert v-if="turnLists.length == 0" variant="primary">
          <div class="alert-body">
            <span class="ml-25">ไม่มีรายการ</span>
          </div>
        </b-alert>
      </b-card>
      <b-card title="รายการถอนล่าสุด 🙌" sm="6">
        <b-table responsive="sm" :items="items">
          <template #cell(status)="data">
            <b-badge pill :variant="data.value.variant">{{
              data.value.title
            }}</b-badge>
          </template>

          <!-- Optional default data cell scoped slot -->
          <template #cell()="data">
            {{ data.value }}
          </template>
        </b-table>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { required, integer } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BBadge,
  BCardText,
  BAlert
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import BalanceBox from './component/BalanceBox.vue'

import { getUserData } from '../auth/utils'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    BButton,
    BTable,
    BBadge,
    BalanceBox,
    BCardText,
    BAlert
  },
  data() {
    return {
      amount: 0,
      items: [],
      user: null,
      turnover: 0,
      turnStatus: false,
      turnCode: 0,
      turnLists: [],
      turnListFields: ['ลำดับ', 'จำนวนเทิร์น', 'โปรที่รับ'],
      sumTotal: 0,
      // validation rules
      required,
      integer
    }
  },
  methods: {
    transformT(txs) {
      const resp = []
      txs.forEach(element => {
        let v = 'light-warning'
        if (element.status === 'confirmed') {
          v = 'light-success'
        } else if (element.status === 'reject') {
          v = 'light-danger'
        }

        resp.push({
          Tx: element.id,
          date_time: `${element.date} ${element.time}`,
          amount: element.amount,
          status: {
            title: element.status,
            variant: v
          },
          remark: element.remark
        })
      })
      return resp
    },
    loggedOut() {
      if (this.user.id === 25953) {
        console.log('Logout')
        localStorage.removeItem('userData')
        this.$router.push('/')
      }
    },
    async getTurnOver() {
      const resp = await this.$http.post('member/getTurnover')
      console.log(resp.data)
      this.turnCode = resp.data.code
      if (resp.data.success) {
        this.turnStatus = resp.data.success
        this.turnover = resp.data.total
      }
    },

    async getTurnList() {
      const resp = await this.$http.post('member/getTurnoverList')
      this.turnLists = resp.data.lists
    },

    async getTransactions() {
      const tx = await this.$http.get('/member/withdraw/tx')
      // console.log(tx.data)
      if (tx.status === 200) {
        const res = this.transformT(tx.data)
        this.items = res
      }
    },
    async getCredit() {
      // console.log('GETCE')
      const resp = await this.$http.get('/member/credit')
      return resp.data
    },

    showAlert(text) {
      this.$swal({
        text,
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      })
    },

    async withdraw() {
      const credit = await this.getCredit()
      if (credit.ori.code === 0) {
        if (credit.result.credit >= this.amount) {
          this.sumTotal = 0
          this.turnLists.forEach(list => {
            this.sumTotal += list.turn_total
          })
          if (
            this.turnStatus === true &&
            this.turnLists.length > 0 &&
            this.turnover < this.sumTotal
          ) {
            console.log('A', this.turnover)
            console.log('B', this.sumTotal)

            this.showAlert(
              'ไม่สามารถทำรายการถอนได้ เนื่องจากยอดเทิร์นยังไม่ครบ'
            )
          } else if (this.amount > 0) {
            if (this.turnover === 0 && this.turnCode !== 100033) {
              this.showAlert(
                'ยอดเงินที่ฝากเข้ามาล่าสุด ยังไม่ได้เข้าเล่นเดิมพัน จึงยังไม่สามารถทำรายการถอนได้ค่ะ'
              )
            } else {
              console.log('W', this.amount)
              const resp = await this.$http.post('/member/withdraw', {
                amount: this.amount
              })
              if (resp.status === 200) {
                console.log('pass')
                if (resp.data.success) {
                  await this.getTransactions()
                  this.amount = 0
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: resp.data.message,
                      icon: 'EditIcon',
                      variant: 'success'
                    }
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: resp.data.message,
                      icon: 'EditIcon',
                      variant: 'danger'
                    }
                  })
                }
              } else {
                console.log('error')
              }
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'กรุณาระบุจำนวนเงินที่ต้องการถอน',
                icon: 'EditIcon',
                variant: 'danger'
              }
            })
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'จำนวนเครดิตคงเหลือไม่เพียงพอ',
              icon: 'EditIcon',
              variant: 'danger'
            }
          })
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'ไม่สามารถตรวจสอบเครดิตคงเหลือได้กรุณาลองอีกครั้ง',
            icon: 'EditIcon',
            variant: 'danger'
          }
        })
      }
      console.log('With', credit)
    }
  },
  async beforeMount() {
    this.user = getUserData()
    await this.getTransactions()
    await this.getTurnOver()
    await this.getTurnList()
    this.loggedOut()
  }
}
</script>

<style></style>
